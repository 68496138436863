import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { IconsListProps } from './models';

import './IconsList.scss';

export const IconsList = ({ title, iconsList }: IconsListProps) => (
  <Row>
    <Col>
      <section data-testid="icons-list" className="icons-list">
        <DangerouslySetInnerHtml html={title} className="icons-list__header" />

        <ul className="icons-list__icons">
          {iconsList.map(({ icon, iconAlt, text }) => (
            <div className="icons-list__item" key={iconAlt}>
              <Image imageData={icon} alt={iconAlt} className="icons-list__item-icon" />
              <DangerouslySetInnerHtml html={text} className="icons-list__item-text" />
            </div>
          ))}
        </ul>
      </section>
    </Col>
  </Row>
);

export default IconsList;
