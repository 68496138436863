import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IPageLinkLinkProps } from './model';

const PageLink: FC<IPageLinkLinkProps> = ({
  link,
  ariaLabel,
  className,
  children,
  ...restAnchorProps
}) =>
  link ? (
    link.startsWith('/') ? (
      <Link className={className} to={link} aria-label={ariaLabel} data-testid="page-link">
        {children}
      </Link>
    ) : (
      <a
        href={link}
        className={className}
        aria-label={ariaLabel}
        rel="noopener noreferrer"
        data-testid="page-link"
        {...restAnchorProps}
      >
        {children}
      </a>
    )
  ) : null;

export default PageLink;
