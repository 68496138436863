import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import classnames from 'classnames';

import { Col, Row } from 'layout';
import CarouselWrapper from 'common/CustomCarousel';
import parseAnchor from 'utils/parseAnchor';

import { ShoppingOptionsProps } from './models';
import ShoppingOptionsCard from './ShoppingOptionsCard';

import './ShoppingOptions.scss';
import './ShadowShoppingOptions.scss';

const ShoppingOptions: FC<ShoppingOptionsProps> = ({
  shoppingOptionsTitle,
  shoppingOptionsCard,
  secondaryNavigationAnchor,
  shoppingOptionsCardCentered,
}) => (
  <Row
    className={classnames('shopping-options', {
      'shopping-options--xl-centered': shoppingOptionsCardCentered === '1',
    })}
    data-testid="shopping-options"
    id={parseAnchor(secondaryNavigationAnchor)}
  >
    <Col>
      <h2 className="shopping-options__title">{shoppingOptionsTitle}</h2>
      {shoppingOptionsCard.length ? (
        <CarouselWrapper>
          {shoppingOptionsCard.map((card) => {
            const [dropId] = useId();

            return <ShoppingOptionsCard {...card} key={dropId} />;
          })}
        </CarouselWrapper>
      ) : null}
    </Col>
  </Row>
);

export default ShoppingOptions;
