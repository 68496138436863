import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import { ContactUsTextSectionProps } from '../models.d';

const ContactUsTextSection: FC<ContactUsTextSectionProps> = ({ title, description }) => (
  <div className="contact-us-text__section" data-testid="contact-us-text-section">
    {title ? <h3 className="contact-us-text__section-title">{title}</h3> : null}
    <DangerouslySetInnerHtml html={description} className="contact-us-text__section-description" />
  </div>
);

export default ContactUsTextSection;
