import React, { FC } from 'react';
import classNames from 'classnames';

import { IconProps } from './models';

const Icon: FC<IconProps> = ({ icon, style }) => (
  <span
    {...{
      className: classNames('icon', {
        [`icon--${icon}`]: icon,
      }),
      style,
    }}
  />
);

export default Icon;
