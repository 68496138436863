const cardWidth = 360;

export const RESPONSIVE = [
  { max: 420, min: 360 },
  { max: 480, min: 420 },
  { max: 540, min: 480 },
  { max: 600, min: 540 },
  { max: 660, min: 600 },
  { max: 720, min: 660 },
  { max: 780, min: 720 },
].reduce((responsive, breakpoint, idx) => {
  responsive[idx] = {
    breakpoint,
    items: 1,
    partialVisibilityGutter: breakpoint.max - cardWidth,
  };

  return responsive;
}, {});
