import { ResponsiveType } from 'react-multi-carousel';

export const RESPONSIVE: ResponsiveType = {
  lg: {
    breakpoint: { max: 990, min: 650 },
    items: 2.2,
    partialVisibilityGutter: 70,
  },
  m: {
    breakpoint: { max: 550, min: 320 },
    items: 1.2,
    partialVisibilityGutter: 30,
  },
};
