import React, { FC, useEffect } from 'react';
import {
  DirectionsRenderer,
  GoogleMap,
  MarkerClusterer,
  useLoadScript,
} from '@react-google-maps/api';

import Loading from 'common/Loading';

import MarkerWithInfo from './MarkerWithInfo';
import { MapProps } from './models';
import useDirections from './useDirections';

import './Map.scss';

const Map: FC<MapProps> = ({
  mapConfig,
  markers = [],
  directionsServiceOptions,
  googleMarkerYourLocation,
  googleErrorMapLoading,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_BROWSER as string,
  });

  if (loadError) {
    return <div>{googleErrorMapLoading}</div>;
  }

  const { directions, directionsService, shouldSetDirections, directionsCallback } = useDirections({
    directionsServiceOptions,
    isLoaded,
  });

  useEffect(() => {
    if (!shouldSetDirections()) {
      return;
    }

    directionsService!.route(directionsServiceOptions!, directionsCallback);
  }, [directionsService, directionsCallback, directionsServiceOptions]);

  return isLoaded ? (
    <GoogleMap {...mapConfig}>
      <MarkerClusterer>
        {(clusterer) =>
          markers.map((marker) => (
            <MarkerWithInfo
              key={marker.place.id}
              {...{ ...marker, googleMarkerYourLocation, clusterer }}
            />
          ))
        }
      </MarkerClusterer>
      {directionsServiceOptions && directions ? (
        <DirectionsRenderer options={{ directions, markerOptions: { visible: false } }} />
      ) : null}
    </GoogleMap>
  ) : (
    <Loading variant="dark" className="map__loading" />
  );
};

export default Map;
