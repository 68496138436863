import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import useForm from 'hooks/useForm';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Button, { VisualType, VisualVariant } from 'common/Button';
import CheckboxField from 'common/CheckboxField/CheckboxField';
import Field from 'common/Field';
import { gtmService } from 'utils/gtmService';
import { stringToBoolean } from 'utils/stringUtils/stringUtils';

import FormThankYouMessage from './FormThankYouMessage';
import { validate } from './validate';

import './NewsletterForm.scss';

export const NewsletterForm = (props) => {
  const {
    title,
    subtitle,
    disclaimer,
    emailField: email,
    firstNameField: firstName,
    lastNameField: lastName,
    checkboxField: checkbox,
    signUpButton,
    consentText,
    thankYouMessage: { text, title: thankYouMessageTitle, thankYouSocialLinks },
    personalizeAdvertising,
  } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isSent, setIsSent] = useState(false);
  const {
    sharedComponentsSettings: {
      newsletterSignUpData: {
        brand,
        deliveryStatus,
        formId,
        leadCustomerBrandOptIn,
        marketingOptin,
        pp,
        rbCommercialBrandOptIn,
        tierCode,
        agreements,
      },
    },
  } = useStaticQuery(graphql`
    {
      sharedComponentsSettings {
        newsletterSignUpData {
          brand
          deliveryStatus
          formId
          leadCustomerBrandOptIn
          marketingOptin
          pp
          rbCommercialBrandOptIn
          tierCode
          agreements {
            businessId
            consentAcceptedInd
            consentDesc
            mandatoryInd
            revisionId
          }
        }
      }
    }
  `);

  const submit = async (signUpValues) => {
    const date = new Date();
    const Agreements = agreements.map(
      ({ businessId, revisionId, consentAcceptedInd, consentDesc, mandatoryInd }) => ({
        BusinessId: businessId,
        RevisionId: revisionId,
        ConsentAcceptedInd: consentAcceptedInd === 'true',
        ConsentDesc: consentDesc,
        MandatoryInd: mandatoryInd === 'true',
        AgreementDate: date,
        ActivityDate: date,
      })
    );
    if (!executeRecaptcha) {
      return;
    }
    const gRecaptchaResponse = await executeRecaptcha('newsletterSignUpSubmit');
    const body = {
      recaptchaResponse: gRecaptchaResponse,
      cdp: {
        programCode: process.env.GATSBY_CDP_PROGRAM_CODE,
        brandOrgCode: process.env.GATSBY_CDP_BRAND_ORG_CODE,
        accountSource: process.env.GATSBY_CDP_ACCOUNT_SOURCE,
      },
      data: {
        TierCode: tierCode,
        FirstName: signUpValues.firstName,
        LastName: signUpValues.lastName,
        Emails: [
          {
            EmailAddress: signUpValues.email,
            DeliveryStatus: deliveryStatus,
          },
        ],
        JsonExternalData: {
          UnmappedAttributes: {
            PP: stringToBoolean(pp),
            marketingOptin: stringToBoolean(marketingOptin),
            brand,
            lead_customer_brand_opt_in: stringToBoolean(leadCustomerBrandOptIn),
            rb_commercial_brand_opt_in: stringToBoolean(rbCommercialBrandOptIn),
            formId,
            formTime: date,
            regTime: date,
            Accept_targeting: Boolean(signUpValues?.checkbox),
            Accept_profiling: Boolean(signUpValues?.checkbox),
            accepts_marketing: true,
          },
          Agreements,
        },
      },
    };

    await fetch(process.env.GATSBY_CDP_LAMBDA_URL!, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    gtmService.emitGenerateLead(gtmService.formNames.newsletter);
    setIsSent(true);
  };

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    checkbox: false,
  };
  const { values, errors, handleChange, handleSubmit } = useForm(
    initialValues,
    submit,
    validate({ email, firstName, lastName })
  );

  return (
    <div
      className={classNames('newsletterForm__wrapper', {
        'newsletterForm__wrapper--thank-you': isSent,
      })}
    >
      {isSent ? (
        <FormThankYouMessage
          text={text}
          title={thankYouMessageTitle}
          socialLinks={thankYouSocialLinks}
        />
      ) : (
        <div className="newsletterForm" data-testid="newsletterForm">
          {title ? (
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                <p className="newsletterForm__title">{title}</p>
              </Col>
            </Row>
          ) : null}

          <div className="newsletterForm__main">
            {subtitle ? <p>{subtitle}</p> : null}

            {disclaimer ? <p className="newsletterForm__disclaimer">{disclaimer}</p> : null}

            <Form onSubmit={handleSubmit}>
              <Field
                label={firstName.label}
                name="firstName"
                value={values.firstName}
                handleChange={handleChange}
                error={errors.firstName}
                placeholder={firstName.placeholder}
                isRequired={firstName.isRequired}
              />

              <Field
                label={lastName.label}
                name="lastName"
                value={values.lastName}
                handleChange={handleChange}
                error={errors.lastName}
                placeholder={lastName.placeholder}
                isRequired={lastName.isRequired}
              />

              <Field
                label={email.label}
                name="email"
                value={values.email}
                handleChange={handleChange}
                error={errors.email}
                placeholder={email.placeholder}
                isRequired={email.isRequired}
              />

              {personalizeAdvertising ? (
                <DangerouslySetInnerHtml
                  html={personalizeAdvertising}
                  className="newsletterForm__personalize-adv"
                />
              ) : null}

              {checkbox ? (
                <CheckboxField
                  name="checkbox"
                  label={checkbox.label}
                  collapsedText={checkbox.collapsedText}
                  value={values.checkbox}
                  handleChange={handleChange}
                />
              ) : null}

              <div className="newsletterForm__consent">
                <DangerouslySetInnerHtml html={consentText} />
              </div>

              <Button
                visualType={signUpButton.type[0] as VisualType}
                visualVariant={VisualVariant.Dark}
                ariaLabel={signUpButton.ariaLabel}
                type="submit"
              >
                {signUpButton.text}
              </Button>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsletterForm;
