import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { VisualType, VisualVariant } from 'common/Button';
import Image from 'common/Image';
import LinkButton from 'components/LinkButton';
import parseAnchor from 'utils/parseAnchor';

import { CampaignBannerProps } from './models';

import './CampaignBanner.scss';

export const CampaignBanner: FC<CampaignBannerProps> = ({
  secondaryNavigationAnchor,
  bannerImage,
  bannerAlt,
  title,
  description,
  ctaButton: [ctaButton],
  ctaButtonAriaLabel,
  imageProduct,
  imageProductAlt,
  logo,
  logoAlt,
  backgroundColor,
}) => {
  const anchorParsed = parseAnchor(secondaryNavigationAnchor);

  return (
    <Row
      data-testid="campaign-banner"
      id={anchorParsed}
      className={classNames('campaign-banner', {
        [`campaign-banner--${backgroundColor}`]: backgroundColor,
      })}
    >
      <div className="campaign-banner__image-section">
        <Image
          imageData={bannerImage}
          className="campaign-banner__image-section-img"
          data-testid="campaign-banner__image-section-img"
          alt={bannerAlt}
        />
        {imageProduct ? (
          <div className="campaign-banner__image-product">
            <Image
              imageData={imageProduct}
              data-testid="campaign-banner__image-product"
              alt={imageProductAlt || ''}
            />
          </div>
        ) : null}
      </div>
      <div className="campaign-banner__information-section">
        <Col className="campaign-banner__information">
          <DangerouslySetInnerHtml html={title} className="campaign-banner__title" />
          <DangerouslySetInnerHtml
            html={description}
            className="campaign-banner__description"
            data-testid="campaign-banner-description"
          />
          <div className="campaign-banner__link">
            {ctaButton ? (
              <LinkButton
                text={ctaButton.name}
                type={VisualType.Discover}
                url={ctaButton.url}
                ariaLabel={ctaButtonAriaLabel}
                variant={VisualVariant.Light}
              />
            ) : null}
          </div>
        </Col>
      </div>
      {logo ? (
        <div className="campaign-banner__logo-wrapper">
          <div className="campaign-banner__logo">
            <Image imageData={logo} alt={logoAlt || ''} />
          </div>
        </div>
      ) : null}
    </Row>
  );
};

export default CampaignBanner;
