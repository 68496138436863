import React, { FC, useRef } from 'react';
import { useId } from 'react-id-generator';
import Carousel from 'react-multi-carousel';
import useScreenRecognition from 'hooks/useScreenRecognition';

import Card from './Card';
import { RESPONSIVE } from './constants';
import { CardsProps } from './models';

import './Cards.scss';

const Cards: FC<CardsProps> = ({ cards }: CardsProps) => {
  const carousel = useRef<Carousel>(null);
  const keys = useId(cards?.length);
  const { isMobile } = useScreenRecognition();

  return (
    <div className="cards">
      {isMobile ? (
        <Carousel
          containerClass="cards-carousel"
          ref={carousel}
          responsive={RESPONSIVE}
          arrows={false}
          showDots
        >
          {cards?.map((item, index) => (
            <Card key={keys[index]} {...item} />
          ))}
        </Carousel>
      ) : (
        cards?.map((item, index) => <Card key={keys[index]} {...item} />)
      )}
    </div>
  );
};

export default Cards;
