import { Loader } from '@googlemaps/js-api-loader';
import { Coordinates, Location } from 'shared';

import { ErrorCallback, SuccessCallback } from '../models';

const loadScript = async (): Promise<void> => {
  if (typeof google.maps !== 'undefined') {
    return;
  }

  const loader = new Loader({
    apiKey: process.env.GATSBY_GOOGLE_MAPS_API_BROWSER as string,
  });

  await loader.load();
};

const googleGeocoding = (
  onSuccess: SuccessCallback,
  onError: ErrorCallback,
  googleErrorGeocodingLocation: string,
  region?: string
) => {
  const locationToAddress = async ({ lat, lng }: Coordinates): Promise<Location | null> => {
    await loadScript();
    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: { lat, lng }, region }, (results, status) => {
        if (status !== 'OK') {
          onError(googleErrorGeocodingLocation);
          reject(status);

          return;
        }

        onSuccess();
        resolve({
          lat,
          lng,
          formattedAddress: results?.[0]?.formatted_address || '',
        });
      });
    });
  };

  const addressToLocation = async (address: string): Promise<Location | null> => {
    await loadScript();
    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status !== 'OK') {
          onError(googleErrorGeocodingLocation);
          reject(status);

          return;
        }

        onSuccess();
        const [result] = results || [];
        const { lat, lng } = result.geometry.location;

        resolve({
          lat: lat(),
          lng: lng(),
          formattedAddress: result.formatted_address,
        });
      });
    });
  };

  return {
    locationToAddress,
    addressToLocation,
  };
};

export default googleGeocoding;
