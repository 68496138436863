import React, { FC, useState } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';

import { formatDistance } from 'components/StoreFinder/utils';

import { MarkerWithInfoProps } from './models';

import './MarkerWithInfo.scss';

const MarkerWithInfo: FC<MarkerWithInfoProps> = ({
  place: { zip, city, address, name, distance, formattedAddress },
  googleMarkerYourLocation,
  ...markerProps
}) => {
  const [isInfoWindowOpen, setInfoWindowOpen] = useState(false);

  const onMarkerClick = () => setInfoWindowOpen(true);

  const onCloseClick = () => setInfoWindowOpen(false);

  return (
    <Marker {...{ ...markerProps, onClick: onMarkerClick }}>
      {isInfoWindowOpen ? (
        <InfoWindow onCloseClick={onCloseClick}>
          <div className="marker-with-info">
            {formattedAddress ? (
              <p className="marker-with-info__formatted-address mb-1">
                {googleMarkerYourLocation}
                <strong>{formattedAddress}</strong>
              </p>
            ) : (
              <>
                <h5 className="marker-with-info__title">{name}</h5>
                <p>{`${zip} ${city},`}</p>
                <p>{address}</p>
              </>
            )}
            {distance ? (
              <p className="marker-with-info__distance">{formatDistance(distance)}</p>
            ) : null}
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

export default MarkerWithInfo;
