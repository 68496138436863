import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { VisualType, VisualVariant } from 'common/Button';
import Image from 'common/Image';
import LinkButton from 'components/LinkButton';

import { ArticleRecommendedProductsProps } from './models';

import './ArticleRecommendedProducts.scss';

export const ArticleRecommendedProducts = ({ recommended }: ArticleRecommendedProductsProps) => (
  <section data-testid="recommended products">
    {recommended
      ? recommended.map(({ image, description, alt, link: [recommendedLink], linkAriaLabel }) =>
          description && alt && recommendedLink ? (
            <Row key={recommendedLink.udi} className="article-recommended-products no-gutters">
              {image ? (
                <Col className="article-recommended-products__image">
                  <Image imageData={image} alt={alt} />
                </Col>
              ) : null}
              <Col className="article-recommended-products__button">
                <LinkButton
                  text={description}
                  url={recommendedLink.url}
                  type={VisualType.Discover}
                  variant={VisualVariant.Dark}
                  ariaLabel={linkAriaLabel}
                />
              </Col>
            </Row>
          ) : null
        )
      : null}
  </section>
);

export default ArticleRecommendedProducts;
