import React, { FC } from 'react';
import { graphql } from 'gatsby';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { VisualType, VisualVariant } from '../../common/Button';
import LinkButton from '../LinkButton';
import { IProductCardsList } from './models';

import './ProductCardsList.scss';

const ProductCardsList: FC<IProductCardsList> = ({ title, cards, promoImage, promoImageAlt }) => {
  const { isMobile } = useScreenRecognition();

  return (
    <section className="product-cards-list" data-testid="product-cards-list">
      <DangerouslySetInnerHtml html={title} className="product-cards-list__title" />
      {cards?.length ? (
        <ul className="product-cards">
          {promoImage && !isMobile ? (
            <li className="product-cards__promo-image-wrapper">
              <Image
                imageData={promoImage}
                alt={promoImageAlt || ''}
                className="product-card__promo-image"
                objectFit="contain"
              />
            </li>
          ) : null}

          {cards.map(
            ({
              productCardHeader,
              productCardImageAlt,
              productCardLink,
              productCardLinkAreaLabel,
              productCardImage,
            }) => (
              <li className="product-card" key={productCardImageAlt}>
                {productCardImage ? (
                  <Image
                    imageData={productCardImage}
                    alt={productCardImageAlt || ''}
                    className="product-card__image"
                    objectFit="contain"
                  />
                ) : null}
                <div className="product-card__content">
                  <DangerouslySetInnerHtml
                    html={productCardHeader}
                    className="product-card__header"
                  />
                  {productCardLink ? (
                    <LinkButton
                      text={productCardLink.name}
                      type={VisualType.Primary}
                      url={productCardLink.url}
                      ariaLabel={productCardLinkAreaLabel}
                      variant={VisualVariant.Light}
                    />
                  ) : null}
                </div>
              </li>
            )
          )}
        </ul>
      ) : null}
    </section>
  );
};

export const query = graphql`
  fragment ProductCardsListFragment on ProductCardsList {
    title
    cards {
      productCardHeader
      productCardImageAlt
      productCardLinkAreaLabel
      productCardImage {
        ...ImageFragment
      }
      productCardLink {
        url
        name
        queryString
        target
      }
    }
    promoImage {
      ...ImageFragment
    }
    promoImageAlt
  }
`;

export default ProductCardsList;
