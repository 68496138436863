import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import Video from 'components/Video';

import { TextWithVideoProps } from './models';

import './TextWithVideo.scss';

export const TextWithVideo = ({
  title,
  text,
  description,
  media,
  disclaimer,
}: TextWithVideoProps) => {
  let mediaRenderer;
  if (!media) {
    mediaRenderer = null;
  } else if (media.image !== null) {
    mediaRenderer = <Image imageData={media.image} alt={media.alt || ''} />;
  } else if (media.videoId !== null) {
    mediaRenderer = <Video videoId={media.videoId} />;
  } else {
    mediaRenderer = null;
  }

  return (
    <div className="text-with-video">
      <Row data-testid="text-with-video-title">
        <Col>
          <h2>{title}</h2>
          <DangerouslySetInnerHtml html={description} />
        </Col>
      </Row>
      <Row data-testid="text-with-video">
        <Col lg={6} className="text-with-video__media">
          {mediaRenderer}
        </Col>
        <Col
          lg={6}
          className="text-with-video__information"
          data-testid="text-with-video-information"
        >
          <DangerouslySetInnerHtml html={text} />
          {disclaimer ? <div className="text-with-video__disclaimer">{disclaimer}</div> : null}
        </Col>
      </Row>
    </div>
  );
};

export default TextWithVideo;
