import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import { TextBoxProps } from './models';

import './TextBox.scss';

export const TextBox = ({ text, title, sectionTheme }: TextBoxProps) => (
  <Row
    data-testid="textbox"
    className={classNames('textbox', {
      [`textbox--${sectionTheme}`]: sectionTheme,
    })}
  >
    <Col className="textbox__container">
      {title ? <h2 className="textbox__title">{title}</h2> : null}
      {text ? (
        <div className="textbox__text">
          <DangerouslySetInnerHtml html={text} />
        </div>
      ) : null}
    </Col>
  </Row>
);

export const query = graphql`
  fragment FragmentTextBox on Textbox {
    title
    text
  }
`;
export default TextBox;
