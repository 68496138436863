import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';
import parseAnchor from 'utils/parseAnchor';

import { UpparelBannerProps } from './models';
import UpparelItem from './UpparelItem';

import './UpparelBanner.scss';

export const UpparelBanner: FC<UpparelBannerProps> = ({
  secondaryNavigationAnchor,
  backgroundImage,
  backgroundColor,
  backgroundImageAlt,
  title,
  items,
  logo,
  logoAlt,
}) => {
  const anchorParsed = parseAnchor(secondaryNavigationAnchor);

  return (
    <Row
      id={anchorParsed}
      data-testid="upparel-banner"
      className={classNames('upparel-banner', {
        [`upparel-banner--${backgroundColor}`]: backgroundColor,
      })}
    >
      <div className="upparel-banner__image">
        <Image
          imageData={backgroundImage}
          className="upparel-banner__image-img"
          alt={backgroundImageAlt || ''}
        />
      </div>
      <Col className="upparel-banner__content">
        <DangerouslySetInnerHtml html={title} className="upparel-banner__title" />
        <div className="upparel-banner__items">
          {items.map((item, index) => {
            const idx = (index + 1).toString();

            return <UpparelItem key={item.text} index={idx} {...item} />;
          })}
        </div>
        {logo ? (
          <div className="upparel-banner__logo">
            <Image imageData={logo} className="upparel-banner__icon" alt={logoAlt || ''} />
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default UpparelBanner;
