import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { DangerouslySetInnerHtml } from 'layout';
import parseAnchor from 'utils/parseAnchor';

import { SocialSectionProps } from './models';
import SocialItem from './SocialItem';
import { SocialItemProps } from './SocialItem/models';

import './SocialSection.scss';

export const SocialSection = ({ secondaryNavigationAnchor, title, items }: SocialSectionProps) => {
  const anchorParsed = parseAnchor(secondaryNavigationAnchor);

  return (
    <Row data-testid="social-section" id={anchorParsed} className="social-section">
      <Col className="social-section__wrapper">
        <DangerouslySetInnerHtml html={title} className="social-section__title" />
        <div className="social-section__content">
          {items.map((item: SocialItemProps) => (
            <div key={item.logoAlt} className="social-section__content-item">
              <SocialItem {...item} />
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default SocialSection;
