import React, { FC } from 'react';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import SvgImageIcon from 'common/SvgImageIcon';

import { UpparelBannerItemProps } from './models';

import './UpparelItem.scss';

export const UpparelItem: FC<UpparelBannerItemProps> = ({ icon, iconAlt, title, text, index }) => (
  <div className="upparel-item" data-testid="upparel-item">
    <div
      className={classNames('upparel-item__icon', {
        [`upparel-item__icon--${index}`]: index,
      })}
    >
      <SvgImageIcon customClassName="upparel-item__icon-ico" icon={icon} iconAlt={iconAlt} />
    </div>
    <DangerouslySetInnerHtml className="upparel-item__title" html={title} />
    <DangerouslySetInnerHtml className="upparel-item__text" html={text} />
  </div>
);

export default UpparelItem;
