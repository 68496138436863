import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';

import { DangerouslySetInnerHtml } from 'layout';
import SvgImageIcon from 'common/SvgImageIcon';

import { IngredientsItemProps } from './models';

import './IngredientsItem.scss';

const IngredientsItem: FC<IngredientsItemProps> = ({ icon, alt, description, title }) => (
  <Col data-testid="ingredients-item" lg={6} className="ingredients-item">
    <SvgImageIcon customClassName="ingredients-item__icon" icon={icon} iconAlt={alt} />
    <div>
      {title ? <h3 className="ingredients-item__title">{title}</h3> : null}
      <DangerouslySetInnerHtml className="ingredients-item__description" html={description} />
    </div>
  </Col>
);

export default IngredientsItem;
