import React, { FC } from 'react';

import BannerImage from 'components/Banner/BannerImage';
import PageLink from 'components/PageLink';

import { CouponSectionProps } from './models';

import './CouponSection.scss';

const CouponSection: FC<CouponSectionProps> = ({ image, alt, caption, fileToDownload, link }) => (
  <div className="coupon-section" data-testid="coupon-section">
    <PageLink
      link={fileToDownload?.fallbackUrl || link?.[0]?.url}
      ariaLabel={link?.[0]?.name}
      className="coupon-section__link"
    >
      <BannerImage image={image} alt={alt} />
    </PageLink>
    <p className="coupon-section__caption">{caption}</p>
  </div>
);

export default CouponSection;
