import React, { FC, useEffect, useRef, useState } from 'react';

import parseAnchor from 'utils/parseAnchor';

import { WITH_BACKGROUND_COLOR } from './constants';
import { WithBackgroundColorProps } from './models';

import './WithBackgroundColor.scss';

const WithBackgroundColor: FC<WithBackgroundColorProps> = ({
  backgroundColor = WITH_BACKGROUND_COLOR.default,
  children,
  secondaryNavigationAnchor,
}) => {
  const [isHorizontalRule, setHorizontalRule] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperClassName = `${WITH_BACKGROUND_COLOR.className}--${backgroundColor}`;

  const anchorParsed = parseAnchor(secondaryNavigationAnchor);

  useEffect(() => {
    const currentRef = wrapperRef.current;
    const sibling = currentRef!.nextElementSibling;
    setHorizontalRule(!!sibling?.classList.contains(wrapperClassName));
  }, []);

  return (
    <div
      className={wrapperClassName}
      ref={wrapperRef}
      data-testid="bg-color-wrapper"
      id={anchorParsed}
    >
      {children}
      {isHorizontalRule ? (
        <hr className="horizontal-line horizontal-line--with-margin-left-right" />
      ) : null}
    </div>
  );
};

export default WithBackgroundColor;
