import { Location, Place } from 'shared';

import { DEFAULT_CENTER } from './constants';
import { MarkerData } from './Map/models';
import { CreateMarkerParams, GetMarkerIconParams } from './models';

const toRadians = (value: number): number => (value * Math.PI) / 180;

const isValidPoint = (point: Location): boolean =>
  !!point && typeof point.lat === 'number' && typeof point.lng === 'number';

// Use ‘haversine’ formula to calculate distance between two points
// https://www.movable-type.co.uk/scripts/latlong.html
export const calculateDistance = (pointA: Location, pointB: Location): number | undefined => {
  if (!isValidPoint(pointA) || !isValidPoint(pointB)) {
    return;
  }

  const R = 6371000;
  const deltaLat = toRadians(pointB.lat! - pointA.lat!);
  const deltaLng = toRadians(pointB.lng! - pointA.lng!);
  const latA = toRadians(pointA.lat!);
  const latB = toRadians(pointB.lat!);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2) * Math.cos(latA) * Math.cos(latB);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
};

export const formatDistance = (distance: number): string => {
  if (!distance) {
    return '0 m';
  }

  const value = distance > 1000 ? (distance / 1000).toFixed(2) : distance.toFixed();
  const unit = distance > 1000 ? 'km' : 'm';

  return `${value} ${unit}`;
};

export const parseCoordinates = (
  coordinates: string,
  defaultCenter: Location = DEFAULT_CENTER
): Location => {
  try {
    const [lat, lng] = coordinates.split(';');

    return {
      lat: Number(lat),
      lng: Number(lng),
    };
  } catch (e) {
    return defaultCenter;
  }
};

const mapMarkerPath =
  'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z';

export const getMarkerIcon = ({ fillColor, scale = 1 }: GetMarkerIconParams) => ({
  fillColor,
  scale,
  fillOpacity: 1,
  strokeOpacity: 0,
  anchor: {
    x: 11,
    y: 22,
  },
  path: mapMarkerPath,
});

export const prepareMarkerData = ({
  fillColor = '#cf249c',
  place,
  zIndex = 1,
}: CreateMarkerParams): MarkerData => ({
  place,
  fillColor,
  zIndex,
  position: {
    lat: place.lat,
    lng: place.lng,
  },
  icon: getMarkerIcon({
    fillColor,
    scale: 1.6,
  }),
});

export const getPlaceMock = (location: Location): Place => ({
  ...location,
  id: '1',
  name: '',
  zip: '',
  city: '',
  address: '',
});

export const getUserLocationMarker = (userLocation: Location | null) =>
  userLocation
    ? prepareMarkerData({
        fillColor: '#2c2e87',
        place: getPlaceMock(userLocation),
        zIndex: 10,
      })
    : undefined;
