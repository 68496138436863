import { Location } from 'shared';

import { ErrorCallback, Position } from '../models';

const getCurrentPosition = async (
  onError: ErrorCallback,
  googleErrorUserLocation: string
): Promise<Location | null> => {
  const options = {
    timeout: 8000,
    maximumAge: 10000,
  };

  try {
    const position: Position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });

    return {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
  } catch (e) {
    onError(googleErrorUserLocation);

    return null;
  }
};

export default getCurrentPosition;
