import React, { FC, useCallback } from 'react';

import Image from 'common/Image';
import { gtmService } from 'utils/gtmService';

import { IWhereToByListingProps } from './model';

import './WhereToBuyListing.scss';

const WhereToBuyListing: FC<IWhereToByListingProps> = ({ title, cards }) => {
  const retailerClickHandler = useCallback(
    (shopName: string) => () => {
      gtmService.emitRetailerClick({}, shopName);
    },
    []
  );

  return (
    <div className="where-to-buy" data-testid="where-to-buy">
      {title ? <h2 className="where-to-buy__title">{title}</h2> : null}

      <ul className="where-to-buy__list">
        {cards.map(({ label, shopLink, shopLinkAria, image, alt }) => (
          <li className="where-to-buy__card" key={shopLink}>
            <a
              href={shopLink}
              aria-label={shopLinkAria}
              className="where-to-buy__link"
              target="__blank"
              rel="noreferrer noopener"
              onClick={retailerClickHandler(label || shopLinkAria)}
            >
              {label ? <div className="where-to-buy__label">{label}</div> : null}
              <Image imageData={image} alt={alt} className="where-to-buy__image" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WhereToBuyListing;
