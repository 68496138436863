import React, { FC, useCallback, useState } from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import { TextWithPopUpProps } from './models';

import './TextWithPopUp.scss';

const TextWithPopUp: FC<TextWithPopUpProps> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { title, link, description, pdf } = props;

  const toggleModalVisibility = useCallback(() => setShowModal((prevState) => !prevState), []);

  return (
    <div data-testid="TextWithPopUp" className="text-with-pop-up">
      <div className="text-with-pop-up__title-wrapper">
        <span className="text-with-pop-up__title">{title}</span>
        <button
          type="button"
          className="text-with-pop-up__title text-with-pop-up__link"
          onClick={toggleModalVisibility}
        >
          {link}
        </button>
      </div>
      <DangerouslySetInnerHtml className="text-with-pop-up__description" html={description} />
      {showModal ? (
        <div className="modal__background">
          <div className="modal__content-wrapper">
            <iframe title={title} width="100%" height="100%" src={pdf.fallbackUrl} />
            <button type="button" className="modal__close" onClick={toggleModalVisibility}>
              X
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TextWithPopUp;
