import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import { CheckboxFieldProps } from './models';

import './CheckboxField.scss';

const CheckboxField: FC<CheckboxFieldProps> = ({
  name,
  value,
  handleChange,
  label,
  collapsedText,
}) => {
  const [showCollapsedText, setShowCollapsedText] = useState(false);

  useEffect(() => {
    const toggleCollapsedText: HTMLElement | null = document.querySelector('[data-trigger]');

    if (toggleCollapsedText) {
      toggleCollapsedText.onclick = (e) => {
        e.preventDefault();
        setShowCollapsedText((prev) => !prev);
      };
    }
  }, []);

  return (
    <div className="checkbox">
      <div className="checkbox__holder">
        <input
          type="checkbox"
          id={name}
          name={name}
          className="checkbox__input"
          onChange={handleChange}
          value={value}
        />
        <label htmlFor={name} className="checkbox__label">
          <DangerouslySetInnerHtml
            html={label}
            className={classNames('checkbox__text', {
              'checkbox__text--opened': showCollapsedText,
            })}
          />
        </label>
      </div>

      {showCollapsedText ? (
        <DangerouslySetInnerHtml className="checkbox__collapsed-text" html={collapsedText} />
      ) : null}
    </div>
  );
};

export default CheckboxField;
