import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { DangerouslySetInnerHtml } from 'layout';
import { VisualType, VisualVariant } from 'common/Button';
import Image from 'common/Image';
import LinkButton from 'components/LinkButton';
import parseAnchor from 'utils/parseAnchor';

import { DonateBannerProps } from './models';

import './DonateBanner.scss';

const DonateBanner: FC<DonateBannerProps> = ({
  secondaryNavigationAnchor,
  image,
  alt,
  title,
  cta,
  ctaAriaLabel,
  description,
}) => {
  const anchorParsed = parseAnchor(secondaryNavigationAnchor);

  return (
    <Row data-testid="donate-banner" id={anchorParsed} className="donate-banner">
      <div className="donate-banner__image">
        <Image imageData={image} alt={alt} />
      </div>
      <div className="donate-banner__information-section">
        <Col className="donate-banner__information">
          <h2 className="donate-banner__title ">{title}</h2>
          <DangerouslySetInnerHtml className="donate-banner__description" html={description} />
          {cta ? (
            <div className="donate-banner__links">
              <LinkButton
                text={cta[0].name}
                type={VisualType.Primary}
                url={cta[0].url}
                ariaLabel={ctaAriaLabel}
                variant={VisualVariant.Light}
              />
            </div>
          ) : null}
        </Col>
      </div>
    </Row>
  );
};

export default DonateBanner;
