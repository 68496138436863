import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useId } from 'react-id-generator';

import { DangerouslySetInnerHtml } from 'layout';

import ContactUsTextSection from './ContactUsTextSection';
import { ContactUsTextProps } from './models';

import './ContactUsText.scss';

const ContactUsText: FC<ContactUsTextProps> = ({ title, description, sections }) => (
  <Row className="contact-us-text" data-testid="contact-us-text">
    <Col xs={12}>
      <h2 className="contact-us-text__title">{title}</h2>
      <DangerouslySetInnerHtml html={description} className="contact-us-text__description" />
    </Col>
    <Col lg={{ span: 6, offset: 3 }} className="contact-us-text__sections">
      {sections.map((section) => {
        const [key] = useId();

        return <ContactUsTextSection key={key} {...section} />;
      })}
    </Col>
  </Row>
);

export default ContactUsText;
