import React, { ChangeEvent, FC, FormEvent, useRef } from 'react';

import IconCustom from 'common/IconCustom';
import Image from 'common/Image';

import { LocationSearchProps } from './models';

import './LocationSearch.scss';

const LocationSearch: FC<LocationSearchProps> = ({
  title,
  formTitle,
  formInputLabel,
  useLocationCTALabel,
  useLocationIcon,
  useLocationIconAlt,
  onUseYourLocationClick,
  onAddressSubmit,
  status,
  address,
  onAddressChange,
  onSearchInputReset,
  searchButtonLabel,
  searchButtonAria,
  buttonCloseAriaLabel,
}) => {
  const inputId = `location-search-${title}`;
  const isLoading = status.value === 'loading';
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onAddressChange(value);
  };

  const onResetClick = () => {
    onSearchInputReset();
    inputRef?.current?.focus();
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (address.trim().length) {
      onAddressSubmit(address);
    }
  };

  return (
    <div className="location-search" data-testid="location-search">
      <p className="location-search__form-title">{formTitle}</p>

      <form onSubmit={onSubmit} data-testid="location-search-form">
        <label className="location-search__label" htmlFor={inputId}>
          {formInputLabel}
        </label>
        <div className="location-search__form-wrapper">
          <div className="location-search__input-wrapper">
            <input
              ref={inputRef}
              id={inputId}
              type="search"
              aria-label={title}
              className="location-search__input"
              onChange={onChange}
              disabled={isLoading}
              value={address}
            />
            {address ? (
              <button
                type="button"
                aria-label={buttonCloseAriaLabel}
                className="location-search__reset"
                onClick={onResetClick}
                disabled={isLoading}
              >
                <IconCustom icon="x_donts_icon" />
              </button>
            ) : null}
          </div>
          <button
            aria-label={searchButtonAria}
            className="location-search__submit"
            type="submit"
            disabled={isLoading}
          >
            {searchButtonLabel}
          </button>
        </div>
      </form>
      {status.value === 'error' ? (
        <div className="location-search__error">{status.message}</div>
      ) : null}
      <button
        type="button"
        aria-label={useLocationCTALabel}
        className="location-search__use-location"
        onClick={onUseYourLocationClick}
        disabled={isLoading}
      >
        <span className="icon--your-location">
          {useLocationIcon ? <Image imageData={useLocationIcon} alt={useLocationIconAlt} /> : null}
        </span>
        {useLocationCTALabel}
      </button>
    </div>
  );
};
export default LocationSearch;
