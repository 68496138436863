import React, { useRef } from 'react';
import { useId } from 'react-id-generator';
import Carousel from 'react-multi-carousel';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { RESPONSIVE } from './constants';
import { ImagesListProps } from './models';

import 'react-multi-carousel/lib/styles.css';
import './ImagesList.scss';

export const ImagesList = ({ images, title }: ImagesListProps) => {
  const carousel = useRef<Carousel>(null);
  const keys = useId(images?.length);
  const { isMobile } = useScreenRecognition();

  return (
    <div className="images-list">
      <DangerouslySetInnerHtml html={title} className="images-list__title" />
      <div className="images-list__list">
        <Carousel
          containerClass="home-banner-carousel"
          ref={carousel}
          responsive={RESPONSIVE}
          arrows={!!isMobile}
          showDots={!!isMobile}
          partialVisible
        >
          {images?.map((item, index) => (
            <div key={keys[index]} className="images-list__list-item">
              <Image imageData={item.image} alt={item.alt} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default ImagesList;
