import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { DangerouslySetInnerHtml } from 'layout';
import { VisualType, VisualVariant } from 'common/Button';
import Image from 'common/Image';
import SvgImageIcon from 'common/SvgImageIcon';
import LinkButton from 'components/LinkButton';
import parseAnchor from 'utils/parseAnchor';

import { SectionTheme, TextWithImageBackground, TextWithImageProps } from './models.d';

import './TextWithImage.scss';

export const TextWithImage = ({
  text,
  image,
  cta: [cta],
  ctaAriaLabel,
  title,
  alt,
  secondaryNavigationAnchor,
  disclaimer,
  imageOnRight,
  ctaStyle,
  backgroundColor = TextWithImageBackground.White,
  textList,
  sectionTheme,
}: TextWithImageProps) => {
  const { isMobile } = useScreenRecognition();

  return (
    <Row
      data-testid="text-with-image"
      className={classNames('text-with-image', {
        [`text-with-image--right`]: imageOnRight,
        [`text-with-image--${sectionTheme}`]: sectionTheme,
        [`text-with-image--${backgroundColor}`]: backgroundColor,
      })}
      id={parseAnchor(secondaryNavigationAnchor)}
    >
      <Col lg={6} className="text-with-image__image">
        <Image imageData={image} alt={alt} />
      </Col>
      <Col
        lg={6}
        className={classNames('text-with-image__information', {
          [`text-with-image__information--grey`]: backgroundColor === TextWithImageBackground.Grey,
          [`text-with-image__information--left`]: imageOnRight,
        })}
        data-testid="text-with-image-information"
      >
        {!(isMobile && sectionTheme === SectionTheme.toughestStainChallenge) && title ? (
          <h2 className="text-with-image__title">{title}</h2>
        ) : null}
        {text ? (
          <div className="text-with-image__text">
            <DangerouslySetInnerHtml html={text} />
          </div>
        ) : null}

        {textList?.length ? (
          <div className="text-with-image__text">
            {textList.map(({ textContent, icon, iconAlt }) => (
              <div className="text-with-image__text-item" key={text}>
                <SvgImageIcon
                  customClassName="text-with-image__text-icon"
                  icon={icon}
                  iconAlt={iconAlt}
                />
                <DangerouslySetInnerHtml html={textContent} />
              </div>
            ))}
          </div>
        ) : null}

        {!(isMobile && sectionTheme === SectionTheme.toughestStainChallenge) && cta ? (
          <div className="text-with-image__cta">
            <LinkButton
              text={cta.name}
              type={ctaStyle?.length ? ctaStyle : VisualType.Discover}
              url={cta.url}
              ariaLabel={ctaAriaLabel}
              variant={VisualVariant.Dark}
            />
          </div>
        ) : null}
        {disclaimer ? <div className="text-with-image__disclaimer">{disclaimer}</div> : null}
      </Col>
    </Row>
  );
};

export default TextWithImage;
