import { useEffect, useMemo, useState } from 'react';
import usePrevious from 'hooks/usePrevious';
import isEqual from 'lodash/isEqual';

type DirectionsResult = google.maps.DirectionsResult | null;
type DirectionsRequest = google.maps.DirectionsRequest | undefined;

type UseDirectionsParams = {
  directionsServiceOptions: DirectionsRequest;
  isLoaded: boolean;
};

const useDirections = ({ directionsServiceOptions, isLoaded }: UseDirectionsParams) => {
  const cachedDirections = usePrevious<DirectionsRequest>(directionsServiceOptions);
  const [directions, setDirections] = useState<DirectionsResult>(null);

  useEffect(() => {
    if (!directionsServiceOptions) {
      setDirections(null);
    }
  }, [directionsServiceOptions]);

  const directionsService: google.maps.DirectionsService | null = useMemo(
    () => (isLoaded ? new google.maps.DirectionsService() : null),
    [isLoaded]
  );

  const shouldSetDirections = () =>
    directionsService &&
    directionsServiceOptions &&
    !isEqual(cachedDirections, directionsServiceOptions);

  const directionsCallback = (result: DirectionsResult) => {
    setDirections(result);
  };

  return {
    directions,
    directionsService,
    shouldSetDirections,
    directionsCallback,
  };
};

export default useDirections;
