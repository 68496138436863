import React from 'react';

import Image from 'common/Image';

import { FormThankYouMessageProps } from './models';

import './FormThankYouMessage.scss';

export const FormThankYouMessage = ({ text, title, socialLinks }: FormThankYouMessageProps) => (
  <div className="formThankYouMessage" data-testid="thankYou-message">
    {title ? <p className="formThankYouMessage__title">{title}</p> : null}

    {text ? <p className="formThankYouMessage__text">{text}</p> : null}

    <div className="formThankYouMessage__social-items">
      {socialLinks.map((socialLink) => (
        <a
          key={socialLink.name}
          className="formThankYouMessage__social-item"
          href={socialLink.link}
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="formThankYouMessage__image-wrapper">
            {socialLink.socialIcon?.imagePicker ? (
              <Image imageData={socialLink.socialIcon.imagePicker} alt={socialLink.iconAltText} />
            ) : null}
          </div>

          <div className="formThankYouMessage__name">{socialLink.name}</div>
        </a>
      ))}
    </div>
  </div>
);

export default FormThankYouMessage;
