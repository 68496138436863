import { ResponsiveType } from 'react-multi-carousel';

import { LAYOUT_BREAKPOINTS } from 'utils/constants';

export const RESPONSIVE: ResponsiveType = {
  sm: {
    breakpoint: { max: LAYOUT_BREAKPOINTS.smd, min: 0 },
    items: 1,
    partialVisibilityGutter: 20,
  },
  md: {
    breakpoint: { max: LAYOUT_BREAKPOINTS.lg, min: LAYOUT_BREAKPOINTS.smd },
    items: 2,
  },
  lg: {
    breakpoint: { max: 100000, min: LAYOUT_BREAKPOINTS.lg },
    items: 3,
  },
};
