import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { BazaarVoiceReviews } from '@phx-husky/bazaarvoice-components';

import { Container, DangerouslySetInnerHtml } from 'layout';

import { BazaarvoiceReviewsProps } from './model';

import './BazaarvoiceReviews.scss';

const BazaarvoiceReviews: FC<BazaarvoiceReviewsProps> = ({ title, bvProductId }) =>
  bvProductId ? (
    <Container fluid className="bazaarvoice-reviews" id="bv-reviews">
      {title ? (
        <DangerouslySetInnerHtml html={title} className="bazaarvoice-reviews__title" />
      ) : null}
      <BazaarVoiceReviews productId={bvProductId} />
    </Container>
  ) : null;

export const query = graphql`
  fragment BazaarvoiceReviewsFragment on BazaarvoiceReviews {
    title
    bvProductId
  }
`;

export default BazaarvoiceReviews;
