import React, { FC, useEffect } from 'react';
import { GlobalContactForm } from '@phx-husky/global-contact-form';

import { gtmService } from 'utils/gtmService';

import { GlobalContactFormHolderProps } from './models';

import './GlobalContactFormHolder.scss';

const GlobalContactFormHolder: FC<GlobalContactFormHolderProps> = ({ formAddress }) => {
  useEffect(() => {
    function iframeMessageHandler(e: MessageEvent) {
      if (e.data.message === gtmService.iframeMessageFormSubmitted) {
        gtmService.emitGenerateLead(gtmService.formNames.contactUs);
      }
    }

    window.addEventListener('message', iframeMessageHandler);

    return () => window.removeEventListener('message', iframeMessageHandler);
  }, []);

  return formAddress ? (
    <GlobalContactForm
      src={formAddress}
      id="phx-global-form-gatsby"
      title="global-contact-form"
      className="global-contact-form"
    />
  ) : null;
};

export default GlobalContactFormHolder;
