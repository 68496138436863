import React, { FC } from 'react';

import BaseListing from '../BaseListing';
import { ListingType } from '../constants';
import { ListingProps } from '../models';

export const ContentListing: FC<ListingProps> = (props) => (
  <BaseListing
    {...{
      type: ListingType.Content,
      ...props,
      fullDescription: true,
      cta: props.cta ? props.cta : [],
    }}
  />
);

export default ContentListing;
