import React from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { SocialItemProps } from './models';

import './SocialItem.scss';

export const SocialItem = ({ logo, logoAlt, socialLinks }: SocialItemProps) => (
  <div data-testid="social-section-item" className="social-section__item">
    <div className="social-section__item-logo">
      <Image imageData={logo} className="social-section__image-section-img" alt={logoAlt} />
    </div>
    <div className="social-section__item-links">
      {socialLinks.map((socialLink) => (
        <a
          href={socialLink.url[0]?.url}
          target="_blank"
          rel="noreferrer noopener"
          aria-label={socialLink.url[0]?.name}
          key={socialLink.type[0]}
          className="social-section__item-link"
        >
          <span className="accessibility-hidden">{socialLink.type[0]}</span>
          <span className={`social-section__item-social-icon social-icon--${socialLink.type[0]}`} />
          <DangerouslySetInnerHtml
            html={socialLink.title}
            className="social-section__item-title"
            element="span"
          />
        </a>
      ))}
    </div>
  </div>
);

export default SocialItem;
