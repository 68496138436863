import { VisualType } from 'common/Button';

export interface TextWithImageProps {
  text?: string;
  image: Component.ResponsiveImage;
  title?: string;
  alt: string;
  imageOnRight: boolean;
  ctaStyle: VisualType;
  cta: PageContent.LinkType[];
  ctaAriaLabel: string | null;
  secondaryNavigationAnchor: string | null;
  disclaimer?: string;
  backgroundColor: TextWithImageBackground | null;
  textList?: TTextList[];
  sectionTheme?: string;
}

type TTextList = {
  icon: IconPicker;
  iconAlt: string;
  textContent: string;
};

export enum TextWithImageBackground {
  White = 'white',
  Grey = 'grey',
}

export enum SectionTheme {
  toughestStainChallenge = 'napisan-theme toughest-stain',
}
