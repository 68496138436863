import React, { FC, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useId } from 'react-id-generator';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import IngredientsItem from './IngredientsItem';
import { IngredientsProps } from './models';

import './Ingredients.scss';

const Ingredients: FC<IngredientsProps> = ({
  sectionTitle,
  ingredientsList,
  ingredientsCompleteListTitle,
  ingredientsCompleteListText,
}) => {
  const [isCompleteListOpen, setIsCompleteListOpen] = useState(false);
  const dropdownButtonClick = () => setIsCompleteListOpen(!isCompleteListOpen);

  return (
    <Row data-testid="ingredients" className="ingredients-list">
      <Col>
        <h2>{sectionTitle}</h2>

        <Row>
          {ingredientsList
            ? ingredientsList.map((item) => {
                const [key] = useId();

                return <IngredientsItem key={`${item.title}-${key}`} {...item} />;
              })
            : null}
        </Row>

        {ingredientsCompleteListTitle && ingredientsCompleteListText ? (
          <div className="ingredients-complete-list">
            <button
              className="ingredients-complete-list__button"
              onClick={dropdownButtonClick}
              tabIndex={0}
              type="button"
              aria-expanded={isCompleteListOpen}
            >
              <span
                className={classNames('ingredients-complete-list__button-sign', {
                  [`ingredients-complete-list__button-sign--open`]: !isCompleteListOpen,
                })}
              />

              <span>{ingredientsCompleteListTitle}</span>
            </button>

            {isCompleteListOpen ? (
              <DangerouslySetInnerHtml
                html={ingredientsCompleteListText}
                className="ingredients-complete-list__text"
              />
            ) : null}
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default Ingredients;
