import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { StoresListProps } from './model';

import './StoresList.scss';

const StoresList: FC<StoresListProps> = ({ title, text, disclaimer, stores, image, alt }) => (
  <div className="stores">
    <div className="stores__text">
      <p className="stores__title">{title}</p>
      <DangerouslySetInnerHtml html={text} className="stores__description" />
      <ul className="stores__list">
        {stores.map(({ storeTitle, storeLocation, storeFile, storeFileLink, storeCtaLabel }) => (
          <li key={storeTitle} className="stores__card">
            <div>
              <p className="stores__card-title">{storeTitle}</p>
              <p className="stores__card-location">{storeLocation}</p>
            </div>
            <div>
              <a
                className="stores__card-link button button__discover button__discover--dark"
                target="_blank"
                rel="noopener noreferrer"
                href={storeFile?.url || storeFileLink}
              >
                {storeCtaLabel}
              </a>
            </div>
          </li>
        ))}
      </ul>
      <DangerouslySetInnerHtml html={disclaimer} className="stores__disclaimer" />
    </div>
    <div className="stores__image">
      <Image imageData={image} alt={alt} />
    </div>
  </div>
);

export default StoresList;
