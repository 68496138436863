import React, { FC } from 'react';
import Carousel from 'react-multi-carousel';

import { capitalize } from 'utils/stringUtils/capitalize';

import { RESPONSIVE } from './constants';
import { PlacesListProps } from './models';

import './PlacesList.scss';

const PlacesList: FC<PlacesListProps> = ({
  places,
  onPlaceClick,
  googleRouteButton,
  carouselMode,
}) => {
  const placesList = places.map(({ name, address, city, zip, link, social, id, lat, lng }) => (
    <div className="places-list__item" key={id}>
      <div className="places-list__item-info">
        <p className="places-list__item-name">{name}</p>
        <p className="places-list__item-address">
          {`${zip} ${capitalize(city)}, ${capitalize(address)}`}
        </p>
        <a className="places-list__item-link" href={link} target="_blank" rel="noreferrer noopener">
          {social}
        </a>
      </div>
      <button
        className="button button__discover button__discover--dark"
        aria-label={googleRouteButton}
        onClick={() => onPlaceClick({ lat, lng })}
        type="button"
      >
        {googleRouteButton}
      </button>
    </div>
  ));

  if (carouselMode) {
    return (
      <Carousel
        containerClass="places-list"
        sliderClass="places-list__carousel"
        responsive={RESPONSIVE}
        arrows={false}
        showDots={false}
        partialVisible
        infinite
      >
        {placesList}
      </Carousel>
    );
  }

  return (
    <div className="places-list" data-testid="places-list">
      {placesList}
    </div>
  );
};

export default PlacesList;
