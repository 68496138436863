import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { VisualType, VisualVariant } from 'common/Button';
import Image from 'common/Image';
import LinkButton from 'components/LinkButton';

import { FullWidthTeaserProps } from './models';

import './FullWidthTeaser.scss';

export const FullWidthTeaser = ({
  description,
  image,
  cta: [cta],
  ctaAriaLabel,
  title,
  alt,
}: FullWidthTeaserProps) => (
  <Row data-testid="full-width-teaser" className="full-width-teaser">
    <div className="full-width-teaser__information-section">
      <Col className="full-width-teaser__information">
        <h2 className="full-width-teaser__title ">{title}</h2>
        <p className="full-width-teaser__description">{description}</p>
        {cta ? (
          <div className="full-width-teaser__links">
            <LinkButton
              text={cta.name}
              type={VisualType.Discover}
              url={cta.url}
              ariaLabel={ctaAriaLabel}
              variant={VisualVariant.Dark}
            />
          </div>
        ) : null}
      </Col>
    </div>

    <div className="full-width-teaser__image">
      <Image imageData={image} alt={alt} />
    </div>
  </Row>
);

export default FullWidthTeaser;
