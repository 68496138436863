import React from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import { CardProps } from '../models';

export const Card = ({ title, description }: CardProps) => (
  <div className="card">
    <div className="card__title">
      <DangerouslySetInnerHtml html={title} />
    </div>
    <div className="card__description">
      <DangerouslySetInnerHtml html={description} />
    </div>
  </div>
);

export default Card;
