const validateOnChange = (field, fields, errors) => {
  if (!field.value && fields[field.name].isRequired) {
    errors[field.name] = fields[field.name].errorMessage;
  } else if (field.name === 'email' && !/\S+@\S+\.\S+/.test(field.value)) {
    errors.email = fields.email.additionalErrorMessage;
  } else {
    errors[field.name] = undefined;
  }
};

export const validate = (fields) => ({ values, field }) => {
  const errors = {} as any;

  if (field) {
    validateOnChange(field, fields, errors);

    return errors;
  }

  // Check if fields are required
  Object.entries(fields).forEach(([key, value]: any) => {
    if (!values[key] && value.isRequired) {
      errors[key] = value.errorMessage;
    }
  });

  // check if email is valid
  if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = fields.email.additionalErrorMessage;
  }

  return errors;
};
