import React, { FC } from 'react';

import { Col, DangerouslySetInnerHtml, Row } from 'layout';
import Image from 'common/Image';

import { ShoppingButtonsProps } from './models';

import './ShoppingButtons.scss';
import './ShadowShoppingButtons.scss';

const ShoppingButtons: FC<ShoppingButtonsProps> = ({ title, buttons }) => (
  <Row className="shopping-buttons" data-testid="shopping-buttons">
    <Col>
      <DangerouslySetInnerHtml className="shopping-buttons__title" html={title} />
      <ul className="shopping-buttons__list">
        {buttons.map(({ image, link, alt }) => (
          <li key={link} className="shopping-buttons__item">
            <a
              className="shopping-buttons__link"
              href={link}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Image imageData={image} alt={alt} />
            </a>
          </li>
        ))}
      </ul>
    </Col>
  </Row>
);

export default ShoppingButtons;
