import React from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import { VisualType, VisualVariant } from 'common/Button';
import Image from 'common/Image';
import LinkButton from 'components/LinkButton';
import Video from 'components/Video';

import { TextWithMediaProps } from './models';

import './TextWithMedia.scss';

export const TextWithMedia = ({
  title,
  description,
  media,
  ctaButton,
  ctaButtonAriaLabel,
}: TextWithMediaProps) => {
  const mediaRender = media.map((mediaItem) =>
    mediaItem.image ? (
      <div key={mediaItem.alt} className="text-with-media__media-item">
        <Image imageData={mediaItem.image} alt={mediaItem.alt || ''} />
      </div>
    ) : mediaItem.videoId ? (
      <div key={mediaItem.videoId} className="text-with-media__media-item">
        <Video videoId={mediaItem.videoId} />
      </div>
    ) : null
  );

  return (
    <div data-testid="text-with-media" className="text-with-media">
      <div className="text-with-media__wrapper">
        <div className="text-with-media__text">
          <div className="text-with-media__title">
            <DangerouslySetInnerHtml html={title} />
          </div>
          <div className="text-with-media__description">
            <DangerouslySetInnerHtml html={description} />
          </div>
          {ctaButton ? (
            <div className="text-with-media__button">
              <LinkButton
                text={ctaButton[0].name}
                type={VisualType.Discover}
                url={ctaButton[0].url}
                ariaLabel={ctaButtonAriaLabel}
                variant={VisualVariant.Darker}
              />
            </div>
          ) : null}
        </div>
        <div className="text-with-media__media">{mediaRender}</div>
      </div>
    </div>
  );
};

export default TextWithMedia;
