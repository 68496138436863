import React, { FC, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { IframeLoaderProps } from './models';

import './IframeLoader.scss';

const IframeLoader: FC<IframeLoaderProps> = ({
  formScript: [{ formSrc }],
  iframeScript: [{ iframeSrc, queryString }],
  title,
  id,
}) => {
  useEffect(() => {
    if (formSrc) {
      const script = document.createElement('script');
      script.src = formSrc;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <Row className="contact-us">
      <Col>
        {iframeSrc && queryString ? (
          <iframe
            title={title}
            src={`${iframeSrc}${queryString}`}
            frameBorder="0"
            scrolling="no"
            width="100%"
            id={id}
            className="iframe-loader"
          />
        ) : null}
      </Col>
    </Row>
  );
};

export default IframeLoader;
