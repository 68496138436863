import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DangerouslySetInnerHtml } from 'layout';
import NewsletterForm from 'components/NewsletterForm';

import { NewsletterBannerProps } from './models';

import './NewsletterBanner.scss';

export const NewsletterBanner = ({ description, title, newsletter }: NewsletterBannerProps) => (
  <Row className="newsletterBanner" data-testid="newsletter-banner">
    <div className="newsletterBanner__left-section">
      <Col className="newsletterBanner__information">
        <h2 className="newsletterBanner__title">{title}</h2>

        <DangerouslySetInnerHtml html={description} className="newsletterBanner__description" />
      </Col>
    </div>

    <div className="newsletterBanner__right-section">
      <div className="newsletterBanner__newsletter-wrapper">
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
          scriptProps={{ async: true, defer: true, appendTo: 'body' }}
        >
          <NewsletterForm {...newsletter} />
        </GoogleReCaptchaProvider>
      </div>
    </div>
  </Row>
);

export default NewsletterBanner;
