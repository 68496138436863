import React, { useState } from 'react';

import { OrderedListProps } from './models';

import './OrderedList.scss';

export const OrderedList = ({
  orderedList,
  moreButtonText,
  numberOfDisplayedItems = 0,
}: OrderedListProps) => {
  const initialState: boolean = orderedList.length <= numberOfDisplayedItems;
  const [showAllItems, setShowAllItems] = useState(initialState);

  return (
    <div data-testid="ordered-list" className="ordered-list">
      <ol className="ordered-list__container">
        {orderedList.map(({ value }, index) =>
          showAllItems || index < numberOfDisplayedItems ? (
            <li key={value} className="ordered-list__item">
              <span className="ordered-list__item--text">{value}</span>
            </li>
          ) : null
        )}
      </ol>

      {!showAllItems && moreButtonText ? (
        <button
          type="button"
          className="ordered-list__button"
          onClick={() => setShowAllItems(true)}
        >
          {moreButtonText}
        </button>
      ) : null}
    </div>
  );
};

export default OrderedList;
