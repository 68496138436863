import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import { InfoBlockProps } from './models';

import './InfoBlock.scss';

const InfoBlock: FC<InfoBlockProps> = ({ description }) => (
  <div data-testid="info-block" className="info-block">
    <DangerouslySetInnerHtml className="info-block__description" html={description} />
  </div>
);

export default InfoBlock;
