export const bodyAdapter = (body) =>
  body
    ?.map((item) =>
      Object.entries(item)
        .filter(([, val]) => val)
        .reduce(
          (current: any, [key, value]) =>
            Array.isArray(value)
              ? [...current, ...value.map((val) => ({ value: val, component: key }))]
              : [...current, { value, component: key }],
          []
        )
    )
    .flat() || [];
