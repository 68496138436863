import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import { ToPascalCaseFunctionType } from './models';

export const stringToBoolean = (str) => {
  if (str === 'false' || str === '' || !str) {
    return false;
  }

  return true;
};

export const toPascalCase: ToPascalCaseFunctionType = (str) =>
  startCase(camelCase(str)).replace(/ /g, '');

export const hasLongWords = (text: string, max: number = 15): boolean =>
  text.split(' ').some((item: string) => item.length > max);

const stringUtils = {
  toPascalCase,
  stringToBoolean,
  hasLongWords,
};

export default stringUtils;
